<template>
  <div>
    <div class="uk-grid-medium" uk-grid v-if="userDetail">
      <div class="uk-width-5-5">
        <div class="d-flex">
          <nav id="breadcrumbs" class="mb-3">
            <ul>
              <li>
                <router-link :to="{ name: 'company.dashboard' }">
                  <i class="uil-home-alt"></i>
                </router-link>
              </li>
              <li>{{ $t("users.users") }}</li>
              <li>{{ $t("users.edit_user") }}</li>
            </ul>
          </nav>
        </div>

        <div class="card" v-if="formErrors && formErrors.length">
          <div v-for="error in formErrors" class="uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color: red">
              <i class="uil-exclamation-triangle"></i> {{ error }}
            </p>
          </div>
        </div>

        <div class="card mt-4">
          <form class="regular-form" action="">
            <div class="uk-grid-collapse uk-child-width-1-2@s" uk-grid>
              <div class="uk-padding-large position-relative">
                <h4>
                  <i class="uil-user-circle"></i> {{ $t("users.information") }}
                </h4>
                <div class="uk-position-relative my-4">
                  <label class="col-form-label" for="name">{{
      $t("users.photo")
    }}</label>
                  <div class="user-profile-photo m-auto rounded-circle bg-light">
                    <img id="profile-image-holder" class="border border-light" :src="form.photo_link" alt="" />
                  </div>
                  <div class="uk-position-center">
                    <div uk-form-custom style="cursor: pointer">
                      <input ref="file" type="file" @change="onChangeFile" class="m-0" accept="image/*" />
                      <span class="icon-feather-camera icon-medium text-black mt-4">
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="name">{{ $t("users.firstname")
                      }}<span class="required">*</span></label>
                    <input type="text" class="form-control" id="name" name="name" placeholder="" v-model="form.name"
                      required="" />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="surname">{{ $t("users.lastname")
                      }}<span class="required">*</span></label>
                    <input type="text" class="form-control" id="surname" name="surname" placeholder=""
                      v-model="form.surname" required="" />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="mail">{{ $t("general.email")
                      }}<span class="required">*</span></label>
                    <input type="email" class="form-control" id="mail" name="mail" placeholder="" v-model="form.email"
                      required="" />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="phone">{{ $t("general.phone")
                      }}<span v-if="form.login_type_id === 2 ||
      form.authentication_type_id === 3
      " class="required">*</span></label>
                    <div class="uk-position-relative w-100">
                      <vue-tel-input v-model="form.phone" :inputOptions="options"></vue-tel-input>
                    </div>
                    <!-- <input type="text" class="form-control" id="phone" name="phone" placeholder=""
                      v-model="form.phone" /> -->
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="sicil">{{ $t("users.reg_no")
                      }}<span v-if="form.login_type_id === 4" class="required">*</span></label>
                    <input type="text" class="form-control" id="sicil" name="sicil" placeholder=""
                      v-model="form.registration_number" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="sicil">Tc kimlik No<span v-if="form.login_type_id === 3"
                        class="required">*</span></label>
                    <input id="national_id" v-model="form.national_id" class="form-control" name="national_id"
                      placeholder="" type="text" />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="dob">{{
      $t("users.dob")
    }}</label>
                    <input id="dob" v-model="form.dob" class="form-control" name="dob" placeholder="" required=""
                      type="date" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="dob">{{
      $t("profile.login_type")
    }}</label>
                    <select name="login_type" class="form-control" v-model="form.login_type_id">
                      <option v-for="item in login_types" :value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="dob">{{
      $t("profile.authentication_type")
    }}</label>
                    <select name="authentication_type" class="form-control" v-model="form.authentication_type_id">
                      <option v-for="item in authentication_types" :value="item.id" :key="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label">Bilgilendirme Yöntemi</label>
                    <select name="notification_method" class="form-control" v-model="form.notification_method">
                      <option v-for="item in notification_methods" :value="item.value" :key="item.value">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row mt-4">
                  <div class="col-md-12">
                    <h4>
                      <i class="uil-user-circle"></i>
                      {{ $t("users.user_add_to_group") }}
                    </h4>
                    <!-- <multiple-select-input :model.sync="userGroup" :required="false" :searchable="true"
                      :values="formattedUserGroup" @select="form.user_group = $event.id" /> -->
                    <multiselect v-model="userGroup" :allow-empty="true" :multiple="true" :options="formattedUserGroup"
                      :searchable="true" deselectLabel="" label="name" selectLabel="Seçiniz"
                      :tag-placeholder="$t('general.select')" :placeholder="$t('general.select')" track-by="id">
                      <template slot="option" slot-scope="option">
                        {{ option.option.name }}
                      </template>
                    </multiselect>
                  </div>
                </div>
                <hr />
                <div class="form-group row mt-3 input-checkbox">
                  <div class="col-md-12">
                    <h4>
                      <i class="uil-asterisk"></i>
                      {{ $t("users.user_password") }}
                    </h4>
                    <div class="d-flex">
                      <input id="user-password" type="checkbox" checked v-model="passwordStatus" />
                      <label class="ml-2" for="user-password">{{
      $t("users.user_password_auto")
    }}</label>
                    </div>
                    <label class="col-form-label" for="pass">{{ $t("general.password")
                      }}<span class="required">*</span></label>
                    <input disabled type="password" class="form-control" id="pass" name="pass" placeholder=""
                      v-model="form.password" required="" />
                  </div>
                </div>
              </div>
              <div class="uk-padding-large">
                <h4>
                  <i class="uil-bag"></i> {{ $t("users.business_information") }}
                </h4>
                <div class="form-group row" v-if="isSuperAdmin">
                  <div class="col-md-12">
                    <label class="col-form-label" for="institution">
                      {{ $t("users.institution") }}
                      <span class="required">*</span></label>
                    <multiple-select-input :model.sync="company" :required="false" :searchable="true"
                      :values="formattedInstitution" @select="
      form.company_id = $event.id;
    changeCompany();
    " />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="position">{{
      $t("users.mission")
    }}</label>
                    <multiple-select-input :model.sync="position" :required="false" :searchable="true"
                      :values="formattedPosition" @select="form.position_id = $event.id" />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="title">{{
      $t("users.title")
    }}</label>
                    <multiple-select-input :model.sync="title" :required="false" :searchable="true"
                      :values="formattedTitle" @select="form.title_id = $event.id" />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="region">
                      {{ $t("users.area") }}</label>
                    <multiple-select-input :model.sync="region" :required="false" :searchable="true"
                      :values="formattedRegion" @select="form.region_id = $event.id" />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="department">{{
      $t("users.department")
    }}</label>
                    <multiple-select-input :model.sync="department" :required="false" :searchable="true"
                      :values="formattedDepartment" @select="form.department_id = $event.id" />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="branch">
                      {{ $t("users.branch") }}</label>
                    <multiple-select-input :model.sync="branch" :required="false" :searchable="true"
                      :values="formattedBranch" @select="form.branch_id = $event.id" />
                  </div>
                </div>

                <div class="form-group row mt-3 input-checkbox">
                  <div class="col-md-12">
                    <div class="d-flex">
                      <input id="user-admin" v-model="form.is_admin" type="checkbox" checked value="1" />
                      <label class="ml-2" for="user-admin">{{
                        $t("users.competent")
                        }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="uk-card-footer pt-0">
              <div class="float-right">
                <button @click="formSubmit" class="uk-button uk-button-primary small" type="button">
                  {{ $t("general.save") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/assets/components/dataTable/DataTable";
import store from "@/core/services";

import company_module, {
  BASE_URL as COMPANY_BASE_URL,
  GET_ITEMS as GET_INSTITUTIONS,
  MODULE_NAME as COMPANY_MODULE,
  ITEMS as INSTITUTIONS,
} from "@/core/services/store/company.module";

import user_group_module, {
  BASE_URL as USER_GROUP_BASE_URL,
  GET_ITEMS as GET_USER_GROUPS,
  MODULE_NAME as USER_GROUP_MODULE,
  ITEMS as USER_GROUPS,
} from "@/core/services/store/user_group.module";

import position_module, {
  BASE_URL as POSITION_BASE_URL,
  GET_ITEMS as GET_POSITIONS,
  MODULE_NAME as POSITION_MODULE,
  ITEMS as POSITIONS,
} from "@/core/services/store/position.module";

import title_module, {
  BASE_URL as TITLE_BASE_URL,
  GET_ITEMS as GET_TITLES,
  MODULE_NAME as TITLE_MODULE,
  ITEMS as TITLES,
} from "@/core/services/store/title.module";

import region_module, {
  BASE_URL as REGION_BASE_URL,
  GET_ITEMS as GET_REGIONS,
  MODULE_NAME as REGION_MODULE,
  ITEMS as REGIONS,
} from "@/core/services/store/region.module";

import department_module, {
  BASE_URL as DEPARTMENT_BASE_URL,
  GET_ITEMS as GET_DEPARTMENTS,
  MODULE_NAME as DEPARTMENT_MODULE,
  ITEMS as DEPARTMENTS,
} from "@/core/services/store/department.module";

import branch_module, {
  BASE_URL as BRANCH_BASE_URL,
  GET_ITEMS as GET_BRANCHS,
  MODULE_NAME as BRANCH_MODULE,
  ITEMS as BRANCHS,
} from "@/core/services/store/branch.module";

import user_update_module, {
  BASE_URL as USER_UPDATE_BASE_URL,
  ITEM,
  MODULE_NAME as USER_UPDATE_MODULE,
  SUCCESS,
  ERROR as USER_ERRORS,
  UPDATE_USER,
  UPDATE_USER_POST,
} from "@/core/services/store/user.module";

import user_module, {
  GET_ITEM as GET_USER,
  ITEM as USER,
  MODULE_NAME as USER_MODULE,
} from "@/core/services/store/user_detail.module";

import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
import moment from "moment";
import axios from "axios";

const _USER_GROUP_MODULE_NAME = USER_GROUP_MODULE;
const _COMPANY_MODULE_NAME = COMPANY_MODULE;
const _POSITION_MODULE = POSITION_MODULE;
const _TITLE_MODULE = TITLE_MODULE;
const _REGION_MODULE = REGION_MODULE;
const _DEPARTMENT_MODULE = DEPARTMENT_MODULE;
const _BRANCH_MODULE = BRANCH_MODULE;
const _USER_MODULE = USER_MODULE;
const _USER_UPDATE_MODULE = USER_UPDATE_MODULE;

export default {
  name: "UserAdd",
  components: { DataTable, MultipleSelectInput },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_COMPANY_MODULE_NAME, company_module);
    registerStoreModule(_USER_GROUP_MODULE_NAME, user_group_module);
    registerStoreModule(_POSITION_MODULE, position_module);
    registerStoreModule(_TITLE_MODULE, title_module);
    registerStoreModule(_REGION_MODULE, region_module);
    registerStoreModule(_DEPARTMENT_MODULE, department_module);
    registerStoreModule(_BRANCH_MODULE, branch_module);
    registerStoreModule(_USER_MODULE, user_module);
    registerStoreModule(_USER_UPDATE_MODULE, user_update_module);
  },
  data() {
    return {
      options: {
        showDialCode: true,
        placeholder: '5XXXXXXXXX',
        styleClasses: ['uk-input', 'border-0', 'w-100', 'h-0', 'form-input', 'uk-form-input', 'p-0', 'm-0'],
      },
      user_admin: false,
      passwordStatus: true,
      company: {
        id: null,
        name: null,
      },
      userGroup: {
        id: null,
        name: null,
      },
      position: {
        id: null,
        name: null,
      },
      title: {
        id: null,
        name: null,
      },
      region: {
        id: null,
        name: null,
      },
      department: {
        id: null,
        name: null,
      },
      branch: {
        id: null,
        name: null,
      },
      form: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        dob: null,
        registration_number: "",
        user_group: null,
        company_id: null,
        branch_id: null,
        department_id: null,
        region_id: null,
        title_id: null,
        position_id: null,
        password: "",
        is_admin: false,
        login_type_id: 1,
        store_id: null,
        national_id: "",
        notification_method: "email",
        photo: null,
        country_phone_code: ''
      },
      formErrors: [],
      company_id: null,
      login_types: [],
      authentication_types: [],
      notification_methods: [
        { name: "Email ile bilgilendir", value: "email" },
        { name: "Sms ile bilgilendir", value: "sms" },
      ],
    };
  },
  computed: {
    errors() {
      return store.getters[USER_UPDATE_MODULE + "/" + USER_ERRORS];
    },
    success() {
      return store.getters[USER_UPDATE_MODULE + "/" + SUCCESS];
    },
    userDetail: {
      get() {
        return store.getters[USER_MODULE + "/" + USER];
      },
      set(value) { },
    },
    userInfo: {
      get: function () {
        return JSON.parse(this.secureLocalStorage("user"));
      },
      set: function (newValue) { },
    },
    isSuperAdmin() {
      let isAdmin = false;
      if (this.userInfo && this.userInfo.roles && this.userInfo.roles.length) {
        isAdmin = !!this.userInfo.roles.find((item) => item.id == 1);
      }
      return isAdmin;
    },
    branchs: {
      get() {
        return store.getters[BRANCH_MODULE + "/" + BRANCHS];
      },
      set(value) { },
    },
    departments: {
      get() {
        return store.getters[DEPARTMENT_MODULE + "/" + DEPARTMENTS];
      },
      set(value) { },
    },
    regions: {
      get() {
        return store.getters[REGION_MODULE + "/" + REGIONS];
      },
      set(value) { },
    },
    titles: {
      get() {
        return store.getters[TITLE_MODULE + "/" + TITLES];
      },
      set(value) { },
    },
    positions: {
      get() {
        return store.getters[POSITION_MODULE + "/" + POSITIONS];
      },
      set(value) { },
    },
    user_groups: {
      get() {
        return store.getters[USER_GROUP_MODULE + "/" + USER_GROUPS];
      },
      set(value) { },
    },
    institutions: {
      get() {
        return store.getters[COMPANY_MODULE + "/" + INSTITUTIONS];
      },
      set(value) { },
    },
    formattedBranch() {
      let results = [
        {
          id: null,
          name: this.$t("general.choose"),
        },
      ];
      if (this.branchs === null) return [];
      this.branchs.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
        });
      });
      return results;
    },
    formattedDepartment() {
      let results = [
        {
          id: null,
          name: this.$t("general.choose"),
        },
      ];
      if (this.departments === null) return [];

      this.departments.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
        });
      });
      return results;
    },
    formattedRegion() {
      let results = [
        {
          id: null,
          name: this.$t("general.choose"),
        },
      ];
      if (this.regions === null) return [];

      this.regions.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
        });
      });
      return results;
    },
    formattedInstitution() {
      let results = [
        {
          id: null,
          name: this.$t("general.choose"),
        },
      ];
      if (this.institutions === null) return [];

      this.institutions.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
        });
      });
      return results;
    },
    formattedUserGroup() {
      let groups = [];
      if (!(this.user_groups instanceof Array)) {
        return [];
      }
      this.user_groups.forEach((item) => {
        groups.push({
          id: item.id,
          name: item.name,
        });
      });
      return groups;
    },
    formattedPosition() {
      let positions = [
        {
          id: null,
          name: this.$t("general.choose"),
        },
      ];
      if (this.positions === null) return [];

      this.positions.forEach((item) => {
        positions.push({
          id: item.id,
          name: item.name,
        });
      });
      return positions;
    },
    formattedTitle() {
      let titles = [
        {
          id: null,
          name: this.$t("general.choose"),
        },
      ];
      if (this.titles === null) return [];

      this.titles.forEach((item) => {
        titles.push({
          id: item.id,
          name: item.name,
        });
      });
      return titles;
    },
  },
  methods: {
    onChangeFile() {
      this.uploded_file = true;
      let value = this.$refs.file.files[0];
      this.form.photo = value;
      let blob = URL.createObjectURL(value);
      let imageHolder = $("#profile-image-holder");
      imageHolder.attr("src", blob);
    },
    getLoginTypes() {
      let types = [];
      axios
        .get(`api/user/login-types`, {
          headers: this.apiHeaders,
        })
        .then((result) => {
          result.data.forEach((data) => {
            types.push({
              id: data.id,
              name: data.name,
            });
          });
        });
      this.login_types = types;
    },
    getAuthenticationTypes() {
      let types = [];
      axios
        .get(`api/user/authentication-types`, {
          headers: this.apiHeaders,
        })
        .then((result) => {
          result.data.forEach((data) => {
            types.push({
              id: data.id,
              name: data.name,
            });
          });
        });
      this.authentication_types = types;
    },
    formSubmit() {
      let self = this;
      let form = self.form;
      form.user = form.id;
      // form.branch_id = self.form.branch_id;
      // form.department_id = self.form.department_id;
      // form.region_id = self.form.region_id;
      // form.title_id = self.form.title_id;
      // form.position_id = self.form.position_id;
      // form.company_id = self.form.company_id;
      form.country_phone_code = form.phone.substring(0, form.phone.indexOf(' ')).substring(1);
      form.phone = form.phone.substring(form.phone.indexOf(' ') + 1).replaceAll(' ', '');

      if (!form.country_phone_code) {
        if (form.phone.substring(0, 3) === '+90') {
          form.country_phone_code = form.phone.substring(1, 3);
          form.phone = form.phone.substring(3, form.phone.length);
        } else {
          form.country_phone_code = form.phone.substring(1, 4);
          form.phone = form.phone.substring(4, form.phone.length);
        }
      }
      form.store_id = form.branch_id;
      // form.photo = this.form.photo;
      // form.national_id = self.form.national_id;
      // form.notification_method = self.form.notification_method;
      let form_data = new FormData();
      form_data.append("name", form.name);
      form_data.append("surname", form.surname);
      form_data.append("email", form.email);
      form_data.append("phone", form.phone);
      form_data.append("dob", form.dob);
      form_data.append("registration_number", form.registration_number);
      form_data.append("national_id", form.national_id);
      form_data.append("country_phone_code", form.country_phone_code);
      // form_data.append("user_group", form.user_group)
      form_data.append("company_id", form.company_id);
      form_data.append("branch_id", form.branch_id);
      form_data.append("department_id", form.department_id);
      form_data.append("region_id", form.region_id);
      form_data.append("title_id", form.title_id);
      form_data.append("position_id", form.position_id);
      form.password ? form_data.append("password", form.password) : null;
      form.is_admin ? form_data.append("is_admin", form.is_admin) : null;
      form_data.append("login_type_id", form.login_type_id);
      form_data.append("authentication_type_id", form.authentication_type_id);
      form_data.append("notification_method", form.notification_method);
      form.photo ? form_data.append("photo", form.photo) : null;
      form_data.append("_method", "PUT");
      self.formErrors = [];
      if (form.name === null || form.name == "") {
        self.formErrors.push(self.$t("users.required.firstname"));
        self.scrollToTop();
        return false;
      }
      if (form.surname === null || form.surname == "") {
        self.formErrors.push(self.$t("users.required.lastname"));
        self.scrollToTop();
        return false;
      }
      if (form.email === null || form.email === "") {
        self.formErrors.push(self.$t("users.required.email"));
        self.scrollToTop();
        return false;
      }

      let hasPhoneSelectedForAnyType =
        form.authentication_type_id === 3 || form.login_type_id === 2;

      let isPhoneRequired =
        hasPhoneSelectedForAnyType && form?.phone?.length === 0;

      if (isPhoneRequired) {
        self.formErrors.push(self.$t("users.required.phone"));
        self.scrollToTop();
        return false;
      }

      if (
        isPhoneRequired &&
        (form?.phone?.length !== 10 || Number.isInteger(form?.phone))
      ) {
        self.formErrors.push(self.$t("users.phone.invalid"));
        self.scrollToTop();
        return false;
      }
      if (
        form.login_type_id === 2 &&
        (form.phone === null || form.phone == "")
      ) {
        self.formErrors.push(self.$t("users.required.phone"));
        self.scrollToTop();
        return false;
      }
      if (
        form.login_type_id === 3 &&
        (form.national_id === null || form.national_id == "")
      ) {
        self.formErrors.push(self.$t("general.required_national_id"));
        self.scrollToTop();
        return false;
      }
      if (
        form.login_type_id === 4 &&
        (form.registration_number === null || form.registration_number == "")
      ) {
        self.formErrors.push(self.$t("users.required.reg_no"));
        self.scrollToTop();
        return false;
      }
      let group_ids = [];
      if (self.userGroup !== null && self.userGroup.length > 0) {
        self.userGroup.forEach((item) => {
          form_data.append("group_ids[]", item.id);
          group_ids.push(item.id);
        });
      } else {
        form_data.append("group_ids[]", null);
      }

      /*  if (form.user_group === null || form.user_group == ""){
          self.formErrors.push(self.$t('users.required.user_group'));
          self.scrollToTop()
          return false;
        }
        if (form.company_id === null || form.company_id == ""){
          self.formErrors.push(self.$t('users.required.institution'));
          self.scrollToTop()
          return false;
        }
        if (form.branch_id === null || form.branch_id == ""){
          self.formErrors.push(self.$t('users.required.branch'));
          self.scrollToTop()
          return false;
        }
        if (form.department_id === null || form.department_id == ""){
          self.formErrors.push(self.$t('users.required.department'));
          self.scrollToTop()
          return false;
        }
        if (form.region_id === null || form.region_id == ""){
          self.formErrors.push(self.$t('users.required.region'));
          self.scrollToTop()
          return false;
        }
        if (form.title_id === null || form.title_id == ""){
          self.formErrors.push(self.$t('users.required.title'));
          self.scrollToTop()
          return false;
        }
        if (form.position_id === null || form.position_id == ""){
          self.formErrors.push(self.$t('users.required.position'));
          self.scrollToTop()
          return false;
        }*/

      store.dispatch(USER_UPDATE_MODULE + "/" + UPDATE_USER_POST, {
        url: USER_UPDATE_BASE_URL + "/update/" + this.$route.params.id,
        contents: form_data,
        returnType: "stateless",
        acceptPromise: true,
      });
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    getBranchs() {
      this.$store.dispatch(BRANCH_MODULE + "/" + GET_BRANCHS, {
        url: BRANCH_BASE_URL,
        filters: {
          company_id: this.company_id,
        },
      });
    },
    getDepartments() {
      this.$store.dispatch(DEPARTMENT_MODULE + "/" + GET_DEPARTMENTS, {
        url: DEPARTMENT_BASE_URL,
        filters: {
          company_id: this.company_id,
        },
      });
    },
    getRegions() {
      this.$store.dispatch(REGION_MODULE + "/" + GET_REGIONS, {
        url: REGION_BASE_URL,
        filters: {
          company_id: this.company_id,
        },
      });
    },
    getTitles() {
      this.$store.dispatch(TITLE_MODULE + "/" + GET_TITLES, {
        url: TITLE_BASE_URL,
        filters: {
          company_id: this.company_id,
        },
      });
    },
    getPositions() {
      this.$store.dispatch(POSITION_MODULE + "/" + GET_POSITIONS, {
        url: POSITION_BASE_URL,
        filters: {
          company_id: this.company_id,
        },
      });
    },
    getInstitutions() {
      this.$store.dispatch(COMPANY_MODULE + "/" + GET_INSTITUTIONS, {
        url: COMPANY_BASE_URL,
        filters: {
          all: "all",
        },
      });
    },
    getUserGroups() {
      this.$store.dispatch(USER_GROUP_MODULE + "/" + GET_USER_GROUPS, {
        url: USER_GROUP_BASE_URL,
        filters: {
          all: "all",
        },
      });
    },
    getUser() {
      this.$store.dispatch(USER_MODULE + "/" + GET_USER, {
        url: "api/user/detail" + "/" + this.$route.params.id,
      });
    },
    changeCompany() {
      if (this.userDetail.company_id) {
        this.company_id = this.userDetail.company_id;

        let temp = this.userDetail.company_id;
        this.form.company_id = null;
        this.form.company_id = temp;

        this.getPositions();
        this.getTitles();
        this.getRegions();
        this.getDepartments();
        this.getBranchs();

        this.form.position_id = "";
        this.form.title_id = "";
        this.form.region_id = "";
        this.form.department_id = "";
        this.form.branch_id = "";
      }
    },
  },
  mounted() {
    this.getLoginTypes();
    this.getAuthenticationTypes();
    this.getInstitutions();
    this.getUserGroups();
    this.getUser();

    if (!this.isSuperAdmin) {
      this.form.company_id = this.userDetail.company_id;
      this.changeCompany();
    }
  },
  watch: {
    success: function (val) {
      if (val) {
        this.$router.push({
          name: "company.user.list",
          params: { succesMessage: this.$t("general.update_success") },
        });
      }
    },
    errors: function (val) {
      if (val) {
        this.formErrors.push(val);
      }
    },
    passwordStatus(value) {
      if (value) {
        $("#pass").prop("disabled", true);
        this.form.password = "12345678";
      } else {
        $("#pass").prop("disabled", false);
      }
      this.form.password = "";
    },
    userDetail(value) {
      if (value) {
        this.company_id = value.company.id;
        if (this.company_id) {
          this.form = value;
          this.form.phone = '+' + value.country_phone_code + value.phone;
          if (value.dob) {
            this.form.dob = moment(this.form.dob).format("YYYY-MM-DD");
          }
          this.company = value.company;
          this.branch = value.branch;
          this.title = value.title;
          this.department = value.department;
          this.position = value.position;
          this.region = value.region;
          this.userGroup = value.groups;
          value.national_id ? (this.national_id = value.national_id) : null;
          value.notification_method
            ? (this.notification_method = value.notification_method)
            : null;
          this.getBranchs();
          this.getPositions();
          this.getTitles();
          this.getRegions();
          this.getDepartments();
        }

        if (
          value.roles &&
          value.roles.length &&
          value.roles.find((roleItem) => +roleItem.id === 2)
        ) {
          this.form.is_admin = true;
        }
      }
    },
  },
};
</script>
<style scoped>
input[type=file],
input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}

.uk-alert {
  margin: 0;
}
</style>

<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
